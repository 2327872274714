import tw, { css, styled } from "twin.macro"

import { StyledLink } from "../../../Styled/Button"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"

export const Nav = styled.nav`
  ${tw`fixed flex flex-col z-20 pb-0 lg:pb-[3.25rem] lg:pt-12 lg:px-3 lgxl:px-0 inset-0 md:right-auto bg-white md:overflow-x-hidden md:overflow-y-auto w-full invisible lg:h-fit`}
  ${({ active }) => active && tw`translate-x-0 visible`}
  ${({ top }) =>
    top &&
    css`
      top: ${top - 1}px;
    `}
  ${({ isMobile, active }) =>
    isMobile &&
    css`
      opacity: ${active ? "1" : "0"};
      position: static;
      visibility: ${active ? "visible" : "hidden"};
      max-height: ${active ? "9999px" : "0"};
      transition: visibility 0.2s ease-in-out 0.2s, opacity 0.2s ease-in-out 0.2s, max-height 0.2s ease-in-out 0.2s;
    `}
`
export const Overlay = styled.div`
  ${tw`md:block fixed z-20 bg-black inset-0 cursor-pointer invisible opacity-0 transition-all duration-200 -left-full lg:left-0`}
  ${({ active }) => active && tw`visible opacity-60 left-0`}
  ${({ headerHeight }) =>
    headerHeight &&
    css`
      top: ${headerHeight}px;
    `}
`
export const Items = styled.ul`
  ${tw`flex justify-center w-full pl-[1.125rem] lg:pl-0 lg:gap-x-[4%] lgxl:gap-x-[7%]`}
  ${({ isMobile }) => isMobile && tw`flex-col`}
`
export const Item = styled.li`
  ${tw`block last:mb-0 whitespace-nowrap w-auto hover:cursor-pointer lg:hover:cursor-auto`}
  ${({ screen }) => screen && tw`block md:hidden`}
`

export const SubItems = styled.ul`
  ${({ isMobile, isActive }) =>
    isMobile
      ? css`
          opacity: ${isActive ? "1" : "0"};
          visibility: ${isActive ? "visible" : "hidden"};
          max-height: ${isActive ? "9999px" : "0"};
          transition: visibility 0.2s ease-in-out 0.2s, opacity 0.2s ease-in-out 0.2s, max-height 0.2s ease-in-out 0.2s;
        `
      : tw`grid grid-rows-8 grid-flow-col gap-y-3.5 gap-x-7`} }
`

export const Link = styled(StyledRow)`
  ${tw`inline-block text-lg lg:text-sm whitespace-nowrap relative hover:cursor-pointer`}
  ${({ isMobile }) => isMobile && tw`flex justify-between w-full`}
  ${({ isSubLink }) => isSubLink && tw`font-medium lg:font-normal mb-4`}
  ${({ secondary }) => secondary && tw`font-normal`}
`
export const Close = tw(StyledLink)`hidden md:block mb-15.5`
export const Column = styled(StyledColumn)`
  ${({ top }) =>
    !top &&
    css`
      flex-basis: 0;
    `}}
`
export const SubItemHeader = tw(StyledRow)`md:hidden`
export const Row = styled(StyledRow)`
  ${({ inner }) => (inner ? tw`h-full pt-0` : tw`flex-grow`)}
  ${({ active }) =>
    css`
      border-top: ${active ? "1px solid #f7eff6" : "none"};
      padding-top: ${active ? "1.125rem" : "0"};
      margin-top: ${active ? "1.125rem" : "0"};
      transition: padding-top 0.2s ease-in-out 0.2s, margin-top 0.2s ease-in-out 0.2s, border-top 0.1s ease-in-out 0.1s;
    `}
`
export const PrimaryItemHeader = styled(StyledRow)`
  ${tw`inline-block text-lg whitespace-nowrap lg:font-semibold pb-4 lg:pb-6`}
  ${({ isMobile }) => isMobile && tw`flex`}
  ${css`
    transition: padding-bottom 0.2s ease-in-out 0.2s;
  `}  
  ${({ isLast, isActive }) => isLast && (isActive ? tw`pb-4` : tw`pb-0`)}
`

export const PrimaryItem = styled(StyledRow)`
  ${({ isSubLink }) => isSubLink && tw`font-medium lg:font-normal`}
  ${({ multiColourText }) => multiColourText && tw`uppercase font-bold`}
  ${({ multiColourText }) =>
    multiColourText &&
    css`
      background-image: linear-gradient(to left, purple, violet, red, red, orange, yellow, green);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      color: transparent;
    `}
  ${tw`inline-block text-lg md:text-base whitespace-nowrap relative cursor-pointer mt-4 lg:m-0`}
  ${({ isLast }) => isLast && tw`mb-4`}
  ${({ isHeaderLast }) => isHeaderLast && tw`mb-0`}
  ${({ isFirst }) => isFirst && tw`mt-0`}
`

export const IconContainer = styled.div`
  ${css`
    transform: rotate(90deg);
  `}
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(-90deg);
    `}
`
